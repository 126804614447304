<template>
  <div class="login h-screen flex items-center justify-center">
    <div class="login-dialog bg-white p-6 rounded-2xl w-96">
      <form @submit.prevent="login">
        <img :src="require('@/assets/img/logo-icon.png')" class="w-12 mx-auto mb-6">
        <div class="input-container">
          <input
            type="email"
            placeholder="Email address..."
            v-model="email"
          />
        </div>
        <div class="input-container">
          <input
            type="password"
            placeholder="password..."
            v-model="password"
          />
        </div>
        <button type="submit" class="button w-full">
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default {
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login () {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user
          console.log(user)
          this.$router.push('/')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

</script>
